import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import ReactTable2 from "components/ReactTable/ReactTable2";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import MenuList from "@material-ui/core/MenuList";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { ToastContainer, toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Search from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Header from "../../Header";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Popover from "@material-ui/core/Popover";
import BugReport from "@material-ui/icons/Home";
import Book from "@material-ui/icons/Book";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Code from "@material-ui/icons/Person";
import Slide from "@material-ui/core/Slide";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import styles1 from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { BeatLoader } from "react-spinners";
import { ClipLoader } from "react-spinners";
import { Checkbox, DialogContentText, Radio } from "@material-ui/core";
import Select from "react-select";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import play from "../../assets/img/play.png";
import "../../App.css";

import { useSpeechSynthesis } from "react-speech-kit";
import styles from "./DataAnalysisStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Button as Mbutton, Switch } from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import RadioGroup from "@mui/material/RadioGroup";
import { Radio as Mradio } from "@mui/material";
import MTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import ReactTable from "components/ReactTable/ReactTable";
// import { Table as MuiTable } from '@mui/material/Table';

const currentUser = JSON.parse(localStorage.getItem("user"));

const useStyles1 = makeStyles(styles1);
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function createData(first_name, last_name, unit_name, template_name, rootsStudied, totalCareerWordRootsAndCousins, total_words, average_minutes_per_day, parent_email, parent_id, student_id) {
  return { first_name, last_name, unit_name, template_name, rootsStudied, totalCareerWordRootsAndCousins, total_words, average_minutes_per_day, parent_email, parent_id, student_id };
}
export default function DataAnalysis() {
  const [macroRootsReport, setMacroRootsReport] = useState([]);
  const [studentParentReport, setStudentParentReport] = useState([]);
  const [studentParentReportLookup, setStudentParentReportLookup] = useState([]);
  const [loadMacroReport, setLoadMacroReport] = useState(false);
  const [loadMacroStudentReport, setLoadMacroStudentReport] = useState(false);
  const [selectAllStudentReportBatchLookup, setSelectAllStudentReportBatchLookup] = useState(false);
  const [showTableParentReport, setShowTableParentReport] = useState(false);
  const [parent_email, setParent_email] = useState("");
  const [parent_id, setParent_id] = useState("");
  const [student_id, setStudent_id] = useState("");
  const [openParentReportModal, setOpenParentReportModal] = useState(false);
  const [tableDataParentReports, setTableDataParentReports] = useState([]);

  const [studentLoginName, setStudentLoginName] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const columns = React.useMemo(
    () => [
      {
        Header: "Root Spelling",
        accessor: "root_spelling",
      },
      {
        Header: "Root Meaning",
        accessor: "root_meaning",
      },
      {
        Header: "#Headwords",
        accessor: "numberOfHeadwords",
      },
      {
        Header: "# Active Headwords",
        accessor: "activeHeadwords",
      },
      {
        Header: "Avg Freq Of Headords",
        accessor: "averageFrequencyHeadwords",
      },
      {
        Header: "#Words",
        accessor: "numberOfWords",
      },
    ],
    []
  );

  const addOrEditParentEmail = () => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (parent_email == "") {
      return toast.error("parent email must not be empty.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };

      axios
        .post(
          process.env.REACT_APP_API_URL + "add-parent-email", { parent_email, parent_id, student_id },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.data.code !== 200) {
            return toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            window.location.reload();
          }
        })
        .catch((error) => {
          toast.error("Something went wrong. Please try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  }

  const columns1 = React.useMemo(
    () => [
      {
        Header: "Student First Name",
        accessor: "first_name",
      },
      {
        Header: "Student Last Name",
        accessor: "last_name",
      },
      {
        Header: "Unit name",
        accessor: "unit_name",
      },
      {
        Header: "Grammer skills",
        accessor: "template_name",
      },
      {
        Header: "Total Words",
        accessor: "total_words",
      },
      {
        Header: "Roots Studied",
        accessor: "rootsStudied",
      },
      {
        Header: "Avg min/Day For the last 7 days",
        accessor: "average_minutes_per_day",
      },
      {
        Header: "Report Date",
        accessor: "report_date",
      }
    ],
    []
  );
  const columns2 = React.useMemo(
    () => [
      {
        Header: "Select",
        accessor: "select",
      },
      {
        Header: "Student First Name",
        accessor: "first_name",
      },
      {
        Header: "Student Last Name",
        accessor: "last_name",
      },
      {
        Header: "School ID",
        accessor: "school_id",
      },
      {
        Header: "Parent Email",
        accessor: "parent_email",
      },
      {
        Header: "Add/Edit Parent Email",
        accessor: "btn",
      }
    ],
    []
  );

  const removeStudentsParentReports = () => {
    var studentsToRemove = [];
    var inputElements = document.getElementsByClassName("selectedCheckboxStudentReport");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        studentsToRemove.push(inputElements[i].value);
      }
    }
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (studentsToRemove?.length == 0) {
      return toast.error("You must select a student.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      axios
        .post(
          process.env.REACT_APP_API_URL + "remove-student-parent-batch-lookup-report", { studentsToRemove },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.data.code == 200) {
            for (var i = 0; inputElements[i]; ++i) {
              inputElements[i].checked = false;
            }
            const data = res.data.data;
            console.log(data);
            if (data?.length > 0) {
              const arrays = []
              data.forEach(element => {
                const data2 = {
                  ...element,
                  "select": (<input
                    type="checkbox"
                    className="selectedCheckboxStudentReport"
                    value={element["student_id"]}
                  />),
                  "btn": (<IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() => {
                      setParent_email(element.parent_email);
                      setParent_id(element.parent_id);
                      setStudent_id(element.student_id);
                      setOpenParentReportModal(!openParentReportModal);
                    }
                    }
                  >
                    <Edit />
                  </IconButton>)
                }
                arrays.push(data2);
              });
              setStudentParentReportLookup(arrays);
            } else {
              setStudentParentReportLookup([]);
            }
            return toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            return toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((error) => {
          toast.error("Something went wrong. Please try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };
  const selectAllStudentReportBatchLookupFunction = () => {
    var inputElements = document.getElementsByClassName("selectedCheckboxStudentReport");
    if (selectAllStudentReportBatchLookup) {
      for (var i = 0; inputElements[i]; ++i) {
        inputElements[i].checked = false;
      }
      setSelectAllStudentReportBatchLookup(false);
    } else {
      for (var i = 0; inputElements[i]; ++i) {
        inputElements[i].checked = true;
      }
      setSelectAllStudentReportBatchLookup(true);
    }
  }
  const getStudentBatchLookupForParentReports = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + currentUser.accessToken,
    };

    fetch(
      process.env.REACT_APP_API_URL +
      "students-batch-lookup",
      { headers }
    )
      .then((res) => res.json())
      .then((data1) => {
        const data = data1.data;
        if (data?.length > 0) {
          const arrays = []
          data.forEach(element => {
            const data2 = {
              ...element,
              "select": (<input
                type="checkbox"
                className="selectedCheckboxStudentReport"
                value={element["student_id"]}
              />),
              "btn": (<IconButton
                color="primary"
                aria-label="edit"
                onClick={() => {
                  setParent_email(element.parent_email);
                  setParent_id(element.parent_id);
                  setStudent_id(element.student_id);
                  setOpenParentReportModal(!openParentReportModal);
                }
                }
              >
                <Edit />
              </IconButton>)
            }
            arrays.push(data2);
          });
          setStudentParentReportLookup(arrays);
        }
      });

  };
  const generateSTudentParentReportBatchLookup = () => {
    var arrayOfIDs = [];
    var inputElements = document.getElementsByClassName("selectedCheckboxStudentReport");
    for (var i = 0; inputElements[i]; ++i) {
      if (inputElements[i].checked) {
        arrayOfIDs.push(inputElements[i].value);
        // inputElements[i].checked = false;
      }
    }

    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (arrayOfIDs?.length == 0) {
      return toast.error("You must select a student.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      setLoadMacroStudentReport(true);
      console.log(arrayOfIDs,studentParentReportLookup);
      // Map array of objects where element.student_id is included in arrayOfIDs
      const students = studentParentReportLookup.filter(obj => arrayOfIDs.includes(obj.student_id?.toString()))
        .map(obj => {
          const newObj = { student_id: parseInt(obj.student_id) };
          if (obj.parent_id !== undefined) {
            newObj.parent_id = obj.parent_id;
          }
          return newObj;
        });
        console.log(students);
      axios
        .post(
          process.env.REACT_APP_API_URL + "student-parent-reports-batch-lookup", { students },
          {
            headers,
          }
        )
        .then((res) => {
          if (res.data.code == 200) {
            for (var i = 0; inputElements[i]; ++i) {
              if (inputElements[i].checked) {
                inputElements[i].checked = false;
              }
            }
            return toast.info(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            return toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((error) => {
          for (var i = 0; inputElements[i]; ++i) {
            if (inputElements[i].checked) {
              inputElements[i].checked = false;
            }
          }
          //   console.log('error',error);
          //   // setLoadMacroStudentReport(false);
          //   toast.error(error, {
          //     position: toast.POSITION.TOP_CENTER,
          //   });
          // return toast.info(`This process can take a few minutes to generate a data report...`, {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        });
    }
  };
  const addStudentBatchLookupParentReport = () => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (schoolId == "") {
      return toast.error("School Id must not be empty.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (studentLoginName == "") {
      return toast.error("Student login name must not be empty.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      setLoadMacroStudentReport(true);
      axios
        .post(
          process.env.REACT_APP_API_URL + "add-student-parent-batch-lookup-report", { studentLoginName, schoolId },
          {
            headers,
          }
        )
        .then((res) => {
          setLoadMacroStudentReport(false);
          if (res.data.code !== 200) {
            return toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            setLoadMacroStudentReport(false);
            const data = res.data.data;
            if (data?.length > 0) {
              const arrays = []
              data.forEach(element => {
                const data2 = {
                  ...element,
                  "select": (<input
                    type="checkbox"
                    className="selectedCheckboxStudentReport"
                    value={element["student_id"]}
                  />),
                  "btn": (<IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() => {
                      setParent_email(element.parent_email);
                      setParent_id(element.parent_id);
                      setStudent_id(element.student_id);
                      setOpenParentReportModal(!openParentReportModal);
                    }
                    }
                  >
                    <Edit />
                  </IconButton>)
                }
                arrays.push(data2);
              });
              setStudentParentReportLookup(arrays);
            }
            return toast.success(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadMacroStudentReport(false);
          toast.error("Something went wrong. Please try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };
  const getSTudentParentReport = (isNew) => {
    setStudentParentReport([]);
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      setLoadMacroStudentReport(true);
      axios
        .post(
          process.env.REACT_APP_API_URL + "student-parent-reports", { studentLoginName, schoolId, isNew },
          {
            headers,
          }
        )
        .then((res) => {
          setLoadMacroStudentReport(false);
          if (res.data.code == 202) {
            return toast.info(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.data.code !== 200) {
            return toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            const data = res.data.data;
            if (data && data.length > 0) {
              data.sort((a, b) => {
                // Convert 'report_date' properties to Date objects for comparison
                const dateA = new Date(a.report_date);
                const dateB = new Date(b.report_date);

                // Compare the dates
                return dateB - dateA;
              });
              setStudentParentReport(data)
            }
          }
          setLoadMacroStudentReport(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadMacroStudentReport(false);
          toast.error("Something went wrong. Please try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };
  const getMacroRootsReport = (newData) => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      if(newData=="true"){
        toast.info("Kindly wait approximately 15 minutes while the data is being generated.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      // setLoadMacroReport(true);
      fetch(
        process.env.REACT_APP_API_URL + "create_macro_roots_report/" + newData,
        {
          headers,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setMacroRootsReport(data.data);
          setLoadMacroReport(false);
        })
        .catch((error) => {
          setLoadMacroReport(false);
        });
    }
  };
  const fetchDataParentReports = async () => {
    setTableDataParentReports([])
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser.accessToken,
      };
      fetch(
        process.env.REACT_APP_API_URL + "students-batch-lookup-reports",
        {
          headers,
        }
      )
        .then((res) => res.json())
        .then((data1) => {
          let data = data1.data;
          if (data?.length > 0) {
            // Assuming the data received is an array of objects with the required properties
            const formattedData = data.map(item => createData(item.first_name, item.last_name, item.unit_name, item.template_name, item.rootsStudied, item.totalCareerWordRootsAndCousins, item.total_words, item.average_minutes_per_day, item.parent_email, item.parent_id, item.student_id));
            // Set the fetched data into the state variable
            setTableDataParentReports(formattedData);
          }

        })
        .catch((error) => {
          toast.error("Something went wrong. Please try again.", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  useEffect(() => {
    if (new Date() > new Date(currentUser.expiresInTime)) {
      toast.error("Unautorized User. Please log In", {
        position: toast.POSITION.TOP_CENTER,
      });
      localStorage.removeItem("user");
      window.location.href = "/";
    } else if (currentUser.role !== "Super Admin") {
      toast.error("Unautorized User. Only the super admin has access", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      getStudentBatchLookupForParentReports();
    }
  }, []);


  return (
    <div>
      {/* <Header/> */}
      <div className="form-group">
        <ToastContainer />
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title=""
            headerColor="primary"
            tabs={[
              {
                tabName: "Reports",
                // tabIcon: Code,setShowSentencePOS
                tabContent: (
                  <>
                    <CustomTabs
                      title=""
                      headerColor="primary"
                      tabs={[
                        {
                          tabName: "Macro Roots Report",
                          // tabIcon: Code,setShowSentencePOS
                          tabContent: (
                            <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6}>
                                <Button
                                  color="primary"
                                  onClick={() => getMacroRootsReport("false")}
                                >
                                  Fetch the Macro Roots Report
                                </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                <Button
                                  color="secondary"
                                  onClick={() => getMacroRootsReport("true")}
                                >
                                  Recreate the Macro Roots Report
                                </Button>
                                </Grid>
                              </Grid>

                              <span>
                                {loadMacroReport ? (
                                  <BeatLoader
                                    color={"#BB2BFF"}
                                    loading={loadMacroReport}
                                    size={20}
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                              <Grid container spacing={2}>
                                <Grid item xs={10} sm={10} md={12}>
                                  <br />
                                  <ReactTable
                                    columns={columns}
                                    data={macroRootsReport}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          ),
                        },
                        // {
                        //   tabName: "Student-Parent Reports",
                        //   // tabIcon: Code,setShowSentencePOS
                        //   tabContent: (
                        //     <>
                        //       <Grid container spacing={2}>
                        //         <Grid item xs={12} sm={2} md={2}>
                        //           <CustomInput
                        //             labelText=" Student Login name"
                        //             id="student_logon_name"
                        //             formControlProps={{
                        //               fullWidth: true,
                        //             }}
                        //             inputProps={{
                        //               value: studentLoginName,
                        //               onChange: (e) =>
                        //                 setStudentLoginName(e.target.value),
                        //             }}
                        //           />
                        //         </Grid>
                        //         <Grid item xs={12} sm={3} md={3}>
                        //           <CustomInput
                        //             labelText=" School ID"
                        //             id="shool_id"
                        //             formControlProps={{
                        //               fullWidth: true,
                        //             }}
                        //             inputProps={{
                        //               value: schoolId,
                        //               onChange: (e) =>
                        //                 setSchoolId(e.target.value),
                        //             }}
                        //           />
                        //         </Grid>
                        //         <Grid item xs={12} sm={3} md={3}>
                        //           <Button
                        //             color="primary"
                        //             onClick={(e) => getSTudentParentReport(false)}
                        //           >
                        //             Fetch existing reports
                        //           </Button>
                        //         </Grid>
                        //         <Grid item xs={12} sm={3} md={3}>
                        //           <Button
                        //             color="secondary"
                        //             onClick={(e) => getSTudentParentReport(true)}
                        //           >
                        //             Generate new report
                        //           </Button>
                        //         </Grid>
                        //         <Grid item xs={12} sm={2} md={2}>
                        //           <span>
                        //             {loadMacroStudentReport ? (
                        //               <BeatLoader
                        //                 color={"#BB2BFF"}
                        //                 loading={loadMacroStudentReport}
                        //                 size={20}
                        //               />
                        //             ) : (
                        //               ""
                        //             )}
                        //           </span>
                        //         </Grid>
                        //       </Grid>
                        //       <span>
                        //         {loadMacroReport ? (
                        //           <BeatLoader
                        //             color={"#BB2BFF"}
                        //             loading={loadMacroReport}
                        //             size={20}
                        //           />
                        //         ) : (
                        //           ""
                        //         )}
                        //       </span>
                        //       <Grid container spacing={2}>
                        //         <Grid item xs={10} sm={10} md={12}>
                        //           <br />
                        //           <ReactTable
                        //             columns={columns1}
                        //             data={studentParentReport}
                        //           />
                        //         </Grid>
                        //       </Grid>
                        //     </>
                        //   ),
                        // },
                        {
                          tabName: "Batch Lookup-Parent Reports",
                          // tabIcon: Code,setShowSentencePOS
                          tabContent: (
                            <>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={3} md={3}>
                                  <FormGroup>
                                    <FormControlLabel control={<Switch size="x-large" onChange={(e) => { setShowTableParentReport(!showTableParentReport) }} checked={showTableParentReport} />} label={showTableParentReport ? "Show table batch" : "Show all data Reports"} />
                                  </FormGroup>
                                </Grid >
                              </Grid >
                              {showTableParentReport ?
                                <>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={3} md={3}>
                                      <Button
                                        color="primary"
                                        onClick={fetchDataParentReports}
                                      >
                                        Fetch batch lookup report
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  <TableContainer component={Paper}>
                                    <MTable sx={{ minWidth: 650 }} aria-label="simple table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell >Student First Name</TableCell>
                                          <TableCell >Student Last Name</TableCell>
                                          <TableCell >Unit name</TableCell>
                                          <TableCell >Grammer skills</TableCell>
                                          <TableCell >Roots Studied</TableCell>
                                          <TableCell >Root Cousins</TableCell>
                                          <TableCell >Lesson Words</TableCell>
                                          <TableCell >Avg min/Day For the last 7 days</TableCell>
                                          <TableCell >Parent Email</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {tableDataParentReports.map((row, i) => (
                                          <TableRow
                                            key={i}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                          >
                                            <TableCell >{row.first_name}</TableCell>
                                            <TableCell >{row.last_name}</TableCell>
                                            <TableCell >{row.unit_name}</TableCell>
                                            <TableCell >{row.template_name}</TableCell>
                                            <TableCell >{row.rootsStudied}</TableCell>
                                            <TableCell >{row.totalCareerWordRootsAndCousins}</TableCell>
                                            <TableCell >{row.total_words}</TableCell>
                                            <TableCell >{row.average_minutes_per_day ? row.average_minutes_per_day : 0}</TableCell>
                                            <TableCell >{row.parent_email}</TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </MTable>
                                  </TableContainer>
                                </> : <>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={2} md={2}>
                                      <CustomInput
                                        labelText=" Student Login name"
                                        id="student_logon_name"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: studentLoginName,
                                          onChange: (e) =>
                                            setStudentLoginName(e.target.value),
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                      <CustomInput
                                        labelText=" School ID"
                                        id="shool_id"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: schoolId,
                                          onChange: (e) =>
                                            setSchoolId(e.target.value),
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                      <Button
                                        color="primary"
                                        onClick={addStudentBatchLookupParentReport}
                                      >
                                        Add Student
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                      <Button
                                        color="secondary"
                                        onClick={generateSTudentParentReportBatchLookup}
                                      >
                                        Generate new batch lookup report
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={2} md={2}>
                                      <span>

                                      </span>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={2}>
                                    <br />
                                    <Grid item xs={2} sm={2} md={2}>
                                      <IconButton onChange={selectAllStudentReportBatchLookupFunction}>
                                        <Checkbox /> </IconButton><strong>{!selectAllStudentReportBatchLookup ? "Select " : "Unselect "} All</strong>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6}>
                                      <Button
                                        color="danger"
                                        onClick={removeStudentsParentReports}
                                      >
                                        Remove students
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={2}>
                                    <Grid item xs={10} sm={10} md={12}>
                                      <br />
                                      <ReactTable
                                        columns={columns2}
                                        data={studentParentReportLookup}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Dialog

                                    open={openParentReportModal}
                                    onClose={() => setOpenParentReportModal(false)}
                                    aria-labelledby="modal-slide-title"
                                    aria-describedby="modal-slide-description"
                                  >
                                    <DialogTitle
                                      id="classic-modal-slide-title"
                                      style={{ textAlign: "center" }}
                                    >
                                      <span style={{ textAlign: "center" }}>
                                        {" "}
                                        {parent_email !== undefined ? "Edit Parent Email" : "Add Parent Email"}
                                      </span>
                                    </DialogTitle>
                                    <DialogContent>
                                      <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                          <CustomInput
                                            labelText="email"
                                            id="parent_email"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}

                                            inputProps={{
                                              value: parent_email,
                                              onChange: (e) =>
                                                setParent_email(e.target.value),
                                            }}
                                          />
                                        </GridItem>
                                      </GridContainer>
                                    </DialogContent>

                                    <DialogActions>
                                      <Button
                                        autoFocus
                                        color="primary"
                                        onClick={() => setOpenParentReportModal(false)}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        autoFocus
                                        onClose={() => setOpenParentReportModal(true)}
                                        color="secondary"
                                        onClick={addOrEditParentEmail}
                                      >
                                        {parent_email !== undefined ? "Update" : "Submit"}
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </>}
                            </>
                          ),
                        },
                      ]}
                    />
                  </>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
